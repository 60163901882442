/**
 * Created by tobias on 16.08.2016.
 */

$(document).ready(function () {
    $('input[name="url[url]"]').change(function () {
        var $this = $(this);
        $this.val(QReturn.Utils.makeHttpUrl($this.val()));
    });
    var $formGroup = $('input[name="url[name]"]').closest('.form-group').hide();
    $('input[name="url[make_dynamic]"]').change(function () {
        var $this = $(this);
        var $formGroup = $('input[name="url[name]"]').closest('.form-group');
        if ($this.is(':checked')) {
            $formGroup.show()
        } else {
            $formGroup.hide()
        }
    });

    Dropzone.options.qrfile = {
        url: _siteContext.uploadQrImageUrl || '/',
        method: 'post',
        addRemoveLinks: true,
        maxFiles: 1,
        init: function () {
            this.on('removedfile', function (file) {
                var name =$('input[name="image"]').val();
                if(name != "") {
                    $.ajax({
                        url: _siteContext.uploadQrImageUrl + '/' + name,
                        type: 'DELETE',
                        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}

                    });
                }
            });
            this.on('success', function (file, response) {
                $('input[name="image"]').val(response.file_name);
            });
        },
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
    }

});


var QReturn;
(function (QReturn) {
    var Open;
    (function (Open) {
        var Generator = (function () {
            function Generator(form) {
                this.$form = form;
                this.isDownloadEnabled = false;
                this.siteContext = window._siteContext;
            }

            Generator.prototype.displayChildForm = function (name) {
                $('.subform', this.$form).addClass('hide');
                $('#' + name, this.$form).removeClass('hide');
            };

            Generator.prototype.generateContent = function () {
                var $form = this.$form;
                var deferred = $.Deferred();
                this.clearValidation();
                this.loading(true);
                $.ajax({
                    'url': $form.attr('action'),
                    'method': $form.attr('method'),
                    'data': $form.serializeArray(),
                    'dataType': 'json',
                    'success': function (data) {
                        deferred.resolve(data);
                    },
                    'error': function (xhr, status, erormsg) {
                        if (xhr.status == 400)
                            deferred.reject(xhr.responseJSON);
                    }
                });
                return deferred;
            };

            Generator.prototype.showAuthHint = function(data) {
                $(data.modal).appendTo('body').modal('show');
            };

            Generator.prototype.enableDownload = function (enable) {
                if (!enable) {
                    $('.download').addClass('disabled');
                } else {
                    $('.download').removeClass('disabled');
                }
                this.isDownloadEnabled = enable;
            };

            Generator.prototype.showImage = function (data) {
                $('#qrcode')
                    .attr('src', data.url);
            };

            Generator.prototype.handleResponse = function (data) {
                console.log(data);
                switch (data.status) {
                    case 'requires_auth':
                        Generator.prototype.showAuthHint(data);
                        break;
                    default:
                        Generator.prototype.showImage(data);
                        Generator.prototype.downloadLink(data);
                        break;
                }
            };

            Generator.prototype.downloadLink = function (data) {
                $('#download-png').attr('href', data.download_png).removeClass('disabled');
            };

            Generator.prototype.downloadAsPng = function (size) {
                window.open(this.siteContext.urls.download_png + '&s=' + size);
            };

            Generator.prototype.downloadAsSvg = function () {
                window.open(this.siteContext.urls.download_svg);
            };

            Generator.prototype.downloadAsJpeg = function () {
                window.open(this.siteContext.urls.download_jpeg);
            };

            Generator.prototype.downloadAsEps = function () {
                window.open(this.siteContext.urls.download_eps);
            };

            Generator.prototype.loading = function (isLoading) {
                var $button = $('#submitqr');
                if (isLoading) {
                    $button.data('original', $button.text()).text($button.data('loading')).button('toggle');
                    $('#download-png').addClass('disabled');
                    $('#qrcode')
                        .attr('src', "img/balls.gif");
                } else {
                    $button.text($button.data('original')).button('toggle');
                }
            };

            Generator.prototype.showValidation = function (validation) {
                $.each(validation, function (key, elem) {
                    var name = Utils.buildInputNameFormJSON(key);
                    var $failedField = $('[name="' + name + '"]', this.$form)
                        .after('<span class="help-block">' + elem + '</span>')
                        .closest('.form-group').addClass('has-error');
                    $('#qrcode')
                        .attr('src', "img/fail.png");
                });
            };

            Generator.prototype.clearValidation = function () {
                $('.has-error', this.$form).removeClass('has-error');
                $('.help-block', this.$form).remove();
            };

            Generator.prototype.generate = function () {
                var $form = this.$form;
                var deferred = $.Deferred();
                $.ajax({
                    'url': $form.attr('action'),
                    'xhrFields': {
                        'responseType': 'arraybuffer'
                    },
                    'dataType': 'binary',
                    'method': $form.attr('method'),
                    'data': $form.serializeArray(),
                    'success': function (buffer) {
                        $('#qrcode')
                            .attr('src', "data:image/png;base64,"
                                + QReturn.Utils.arrayBufferToBase64(buffer));
                        deferred.resolve();
                    },
                    'error': function (xhr, status, error_text) {
                        deferred.reject("Failed" + error_text);
                    }
                });
            };
            return Generator;
        }());
        Open.Generator = Generator;
    })(Open = QReturn.Open || (QReturn.Open = {}));

    var Utils = (function () {
        function Utils() {
        }

        Utils.buildQrImageLink = function (content, size, format) {
            return _siteContext.renderUrl + "?q=" + content;
        };

        Utils.buildQrImageDownloadLink = function (hash, format) {
            return _siteContext.downloadUrl + "?h=" + hash + "&f=" + format;
        };

        Utils.makeHttpUrl = function (url) {
            if (!url.match(/^[a-zA-Z]+:\/\//))
                return 'http://' + url;
            return url;
        };

        Utils.buildInputNameFormJSON = function (jsonliteral) {
            var parts = jsonliteral.split(".");
            return parts[0] + '[' + parts[1] + ']';
        };

        Utils.arrayBufferToBase64 = function (buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        };
        return Utils;
    }());
    QReturn.Utils = Utils;
})(QReturn || (QReturn = {}));