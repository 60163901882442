(function () {
    angular
        .module('configApp', ['ngDragDrop'])
        .config(function ($interpolateProvider) {
            $interpolateProvider.startSymbol('{/');
            $interpolateProvider.endSymbol('/}');
        })
        .controller('ConfigCtrl', ['$scope', '$document',
            function ConfigCtrl($scope, dragAndDrop, $document) {
                var configs = [],
                    landingpages = [],
                    qrcodes = [];
                var self = this;

                $scope.initialize = function () {
                    if (window._siteContext === undefined)
                        return;
                    self.configs = _siteContext.configurations;
                    self.landingpages = _siteContext.landing_pages;
                    self.project = _siteContext.project;
                    self.qrcodes = _siteContext.qrcodes;
                    console.log(self.configs);
                };

                var findAndDeleteQrCodeInConfigs = function (qrcodeid, configid) {
                    angular.forEach(self.configs, function (config) {
                        if (config.id != configid) {
                            var pos = findFirstPosition(qrcodeid, config.qrcodes);
                            if (pos >= 0) {
                                config.qrcodes.splice(pos, 1);
                            }
                        }

                    });
                };

                var findFirstPosition = function (needle, haystack) {
                    for (var i = 0; i < haystack.length; i++) {
                        var code = haystack[i];
                        if (code.id == needle) {
                            return i;
                        }
                    }
                    return -1;
                };

                var findAndDeleteLpInConfigs = function (lpid, configid) {
                    angular.forEach(self.configs, function (config) {
                        if (config.id != configid) {
                            var pos = findFirstPosition(lpid, config.landingpages);
                            if (pos >= 0) {
                                config.landingpages.splice(pos, 1);
                            }
                        }
                    });
                };

                self.beforeDropQr = function (event, ui, config) {
                    return new Promise(function (resolve, reject) {
                        var id = ui.draggable.data('qrcodeid');
                        if (findFirstPosition(id, config.qrcodes) < 0) {
                            resolve();
                        }
                        else {
                            reject(Error('nope'));
                        }
                    })
                };

                self.beforeDropLp = function (event, ui, config) {
                    return new Promise(function (resolve, reject) {
                        var id = ui.draggable.data('lpid');
                        if (findFirstPosition(id, config.landingpages) < 0) {
                            resolve();
                        }
                        else {
                            reject(Error('nope'));
                        }
                    })
                };

                self.dropQrCallback = function (event, ui, config) {
                    findAndDeleteQrCodeInConfigs(ui.draggable.data('qrcodeid'), config.id);
                };

                self.dropLpCallback = function (event, ui, config) {
                    findAndDeleteLpInConfigs(ui.draggable.data('lpid'), config.id);
                };
            }
        ])
})
();